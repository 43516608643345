<template>
<div class="booking-view">
    <PtyBack @back="$router.go(-1)"/>
    <div class="action-selection__content">
        <vs-dialog blur v-model="confirmModal">
            <template #header>
                <h1 style="margin-top: 20px;">{{ $t('Booking.confirmModalHeader') }}</h1>
            </template>

            <p>
                {{ $t('Booking.confirmModalMessage') }}
            </p>

            <template #footer>
                <vs-row direction="column" style="gap: 1rem">
                    <PtyButton id="pty__Booking_confirmButton" @click="confirm" block>{{ $t('Booking.confirmButton') }}</PtyButton>
                    <PtyButton id="pty__Booking_cancelButton" @click="confirmModal = false" light-blue block>{{ $t('Booking.cancelButton') }}</PtyButton>
                </vs-row>
            </template>
        </vs-dialog>

        <h1>{{ $t('Booking.bookingTitle') }}</h1>
        <p>
            {{ $t('Booking.bookingMessage') }}
        </p>

        <PtySelector :options="possibleEvents" v-model="selectedEvent" :value="selectedEvent" />

        <PtySelector :options="participants.map(function(e,i){ return {id:i, title: e.name, icon: 'bx-user'}})" v-model="selectedParticipant" colorizeMode="stroke" iconType="boxicon" size="small" :showCheck="true" :gap="1" />

        <div class="pty-divider">
            <hr />
            <p>{{ $t('Booking.enterYourData') }}</p>
            <hr />
        </div>

        <PtySelector :options="possibleGenders" v-model="participant.gender" :value="participant.gender" :block="false" half colorizeMode="stroke" size="md" />

        <vs-row justify="space-between">
            <PtyInput id="pty__Booking_namePlaceholder" v-model="participant.name" :placeholder="$t('Booking.namePlaceholder')" :icon="UserIcon" style="width: 48%;"/>
            <PtyInput id="pty__Booking_surnamePlaceholder" v-model="participant.surname" :placeholder="$t('Booking.surnamePlaceholder')" :icon="UserIcon" style="width: 48%"/>
        </vs-row>
        <PtyInput id="pty__Booking_phonePlaceholder" v-model="participant.phone" :placeholder="$t('Booking.phonePlaceholder')" block :icon="PhoneIcon" />

        <PtyButton id="pty__Booking_addParticipantButton" block @click="addPartecipant">{{ $t('Booking.addParticipantButton') }}</PtyButton>
        <PtyButton id="pty__Booking_confirmAndContinueButton" block light-blue @click="confirmModal = true" :loading="confirmLoading" :disabled="participants.length < 1">{{ $t('Booking.confirmAndContinueButton') }}</PtyButton>
    </div>
</div>
</template>

<script >
import {reservationService, eventService} from '../rest';
import PtyBack from '../components/PtyBack.vue';
import PtyButton from '../components/PtyButton.vue';
import PtySelector from '../components/PtySelector.vue';
import PtyInput from '../components/PtyInput.vue';


import LocationIcon from '../assets/images/icons/location.svg';
import CheckIcon from '../assets/images/icons/check.svg';
import PhoneIcon from '../assets/images/icons/phone.svg';
import UserIcon from '../assets/images/icons/user.svg';
export default {
    name: 'Booking',
    components: {
        PtySelector,
        PtyInput,
        PtyButton,
        PtyBack
    },
    data: () => ({
        UserIcon,
        PhoneIcon,

        confirmModal: false,
        confirmLoading: false,

        possibleEvents: [],
        selectedEvent: '',

        

        participant: {
            name: '',
            surname: '',
            phone: '',
            gender: ''
        },

        participants: [],
        selectedParticipant:null,

        searchVisible: false,
        searchValue: '',
    }),

    computed: {
        possibleGenders(){
            return [{
                id: 'MALE',
                title: this.$t('GenderSelection.options.male.title'),
                icon: CheckIcon
            }, {
                id: 'FEMALE',
                title: this.$t('GenderSelection.options.female.title'),
                icon: CheckIcon
            }]
        }
    },

    methods: {
        getSeatsWarning(total, occupied) {
            if(total == undefined || occupied == undefined) { // FREE EVENT
                return undefined;
            }

            let free = total - occupied;
            if(free <= 0) {
                return this.$t('Booking.Messages.full');
            }

            // if less than 20% free
            if(free / total <= 0.2) {
                // say how many places are left
                return this.$t('Booking.Messages.fewSeatsLeft', { free });
            }

            return undefined;
        },
        confirm() {
            this.confirmLoading = true;
            this.confirmModal = false;

            reservationService.create({
                eventId: this.selectedEvent,
                participants: this.participants
            }).then((reservation) => {
                this.confirmLoading = false;
                this.$router.push({name: 'Reservation', params: {reservation: {
                    id: reservation.id,
                    event: this.possibleEvents[0].title,
                    date: this.possibleEvents[0].day,
                    month: this.possibleEvents[0].month,
                    partecipants: this.participants,
                    location: this.possibleEvents[0].caption,
                    fullEvent: this.possibleEvents[0]
                }}});
            }).catch(() => {
                this.confirmLoading = false;
                this.$vs.notification({
                    title: this.$t('Common.error'),
                    text: this.$t('Booking.Messages.errorBooking'),
                    position: 'top-center',
                    border: 'primary',
                });
            });
        },

        addPartecipant() {
            if(this.participant.added){
                this.participant = {
                    name: '',
                    surname: '',
                    phone: '',
                    gender: ''
                }
                this.selectedParticipant = null;
                return;
            }

            // validate it

            // name must not be empty
            if(!this.participant.name) {
                this.$vs.notification({
                    title: this.$t('Common.error'),
                    text: this.$t('Booking.Messages.errorNameEmpty'),
                    position: 'top-center',
                    border: 'primary',
                });
                return;
            }

            // surname must not be empty
            if(!this.participant.surname) {
                this.$vs.notification({
                    title: this.$t('Common.error'),
                    text: this.$t('Booking.Messages.errorSurnameEmpty'),
                    position: 'top-center',
                    border: 'primary',
                });
                return;
            }

            // phone must not be empty
            if(!this.participant.phone) {
                this.$vs.notification({
                    title: this.$t('Common.error'),
                    text: this.$t('Booking.Messages.errorPhoneEmpty'),
                    position: 'top-center',
                    border: 'primary',
                });
                return;
            }

            // phone must be valid
            if(!this.participant.phone.match(/^\d{10,12}$/)) {
                this.$vs.notification({
                    title: this.$t('Common.error'),
                    text: this.$t('Booking.Messages.errorPhoneInvalid'),
                    position: 'top-center',
                    border: 'primary',
                });
                return;
            }

            // gender must be selected
            if(!this.participant.gender) {
                this.$vs.notification({
                    title: this.$t('Common.error'),
                    text: this.$t('Booking.Messages.errorGenderEmpty'),
                    position: 'top-center',
                    border: 'primary',
                });
                return;
            }

            // max 5 participants
            if(this.participants.length >= 5) {
                this.$vs.notification({
                    title: this.$t('Common.error'),
                    text: this.$t('Booking.Messages.errorMaxParticipants'),
                    position: 'top-center',
                    border: 'primary',
                });
                return;
            }

            this.participants.push({
                ...this.participant,
                added: true
            });

            // reset fields
            this.participant = {
                name: '',
                surname: '',
                phone: '',
                gender: ''
            }


            // show success message
            this.$vs.notification({
                title: this.$t('Common.success'),
                text: this.$t('Booking.Messages.successParticipantAdded'),
                position: 'top-center',
                border: 'primary',
            });
        }
    },

    mounted() {
        const vm = this;
        // get from route
        this.selectedEvent = this.$route.params.eventId;

        // validate that by obtaining it
        eventService.detail({
            id: this.selectedEvent,
        }).then((event) => {
            vm.possibleEvents = [{
                id: event.id,
                title: event.name,
                caption: event.business.address + ', ' + event.business.city,
                icon: LocationIcon,
                day: (new Date(event.start_at)).getDate(),
                month: (new Date(event.start_at)).toLocaleString('default', { month: 'short' }).toUpperCase(),
                extra_caption: event.description,
                start_at: event.start_at,
                end_at: event.end_at,
                business: event.business,
                label: vm.getSeatsWarning(event.total_seats, event.occupied_seats)
            }]
        }).catch(() => {
            this.$vs.notification({
                title: this.$t('Common.error'),
                text: this.$t('Booking.Messages.errorEventNotFound'),
                position: 'top-center',
                border: 'primary',
            });
            this.$router.push({name: 'OnBoarding'});
        });


    },

    watch: {
        selectedParticipant(n){
            if(n == null){
                return;
            }
            this.participant = this.participants[n]
        }
    },
    
}
</script>

<style scoped>
.action-selection__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

h1 {
    color: #000;
    font-family: "Sofia Pro";
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.0425rem;
    margin: 0;
}

p {
    color: #ABABAB;
    text-align: center;
    font-family: "Sofia Pro";
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 177.1%;
    /* 1.88169rem */
}

.booking-view {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
    background: white;
    overflow-y: auto!important;
}
</style>
